@import '../../styles/variables'

.guides
    padding-top: 20px
    &__wrapper
        padding-top: 20px
    &__list
        display: flex
        flex-wrap: wrap
        justify-content: center
        gap: 40px
    &__item
        position: relative
        width: 620px
        height: 350px
        padding: 20px
        display: flex
        gap: 20px
        border-radius: 10px
        box-shadow: 0 0 28px rgba(0,0,0,0.25), 0 0 10px rgba(0,0,0,0.22)
        transition: all 0.5s
        background: #fff
        &:hover
            transform: scale(102%)
            box-shadow: 0 0 28px rgba(0,0,0,0.25), 0 0 10px rgba(0,0,0,0.22)
    &__preview
        display: flex
        flex-direction: column
        justify-content: space-between
        button
            cursor: pointer
            border-radius: 20px
            border: 1px solid $main-color
            background-color: $main-color
            color: #FFFFFF
            font-size: 12px
            font-weight: bold
            padding: 10px 0
            letter-spacing: 1px
            text-transform: uppercase
            transition: transform 80ms ease-in
            &:active
                transform: scale(0.95)
            &:focus
                outline: none
    &__name
        font-size: 24px
        font-weight: 500
        margin-top: 5px
    &__country
        color: $main-color
        font-style: italic
    img
        width: 150px
        height: 150px
        object-fit: cover
        object-position: center
        border-radius: 100%
        text-align: center
    &__about
        display: flex
        flex-direction: column
        height: 270px
        gap: 10px
        letter-spacing: 0.4px
        p
            margin: 0
        span
            font-weight: 500
    &__date
        position: absolute
        color: rgba(0, 0, 0, 0.5)
        right: 20px
        bottom: 20px
    &__modal
        width: 350px
        height: 150px
        border-radius: 20px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        background: #fff
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-45%, -50%)
        box-shadow: 0 0 28px rgba(0,0,0,0.25), 0 0 10px rgba(0,0,0,0.22)
        transition: all 0.5s
        span
            font-weight: 600
        i
            position: absolute
            top: 10px
            right: 10px
            font-size: 24px
            cursor: pointer
        &-none
            display: none           