@import '../../styles/variables'
    
.blog
    padding: 20px 0 50px
    &__wrapper
        padding-top: 20px
    &__list
        display: flex
        flex-wrap: wrap
        justify-content: center
        gap: calc( 80px / 3 )
    &__item
        cursor: pointer
        position: relative
        width: 300px
        height: 450px
        display: flex
        flex-direction: column
        border-radius: 10px
        box-shadow: 0 0 14px rgba(0,0,0,0.25), 0 0 5px rgba(0,0,0,0.22)
        transition: all 0.5s
        background: #fff
        &:hover
            transform: scale(102%)
            box-shadow: 0 0 28px rgba(0,0,0,0.25), 0 0 10px rgba(0,0,0,0.22)
        img
            width: 100%
            height: 150px
            border-radius: 10px 10px 0 0
            object-fit: cover
            object-position: center
    &__content
        padding: 20px
        span
            position: absolute
            bottom: 20px
            color: rgba(0, 0, 0, 0.5)
    &__type
        background: $main-color
        border-radius: 10px 0 10px 0
        color: #fff
        width: 100px
        height: 30px
        position: absolute
        top: 0
        text-align: center
        font-size: 20px
        font-weight: 500
        padding-top: 2px
    &__title
        font-size: 20px
        color: $main-color
    &__descr
        margin-top: 10px