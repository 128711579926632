.footer 
    width: 100%
    height: 100px
    background: #4b4b4b
    color: #fff
    &__wrapper
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 15px
    a
        color: #fff
        text-transform: uppercase
        font-weight: 500
    &__links
        display: flex
        gap: 30px
    span
        font-size: 14px