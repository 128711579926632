@import '../../styles/variables'
    
.home
    .carousel
        width: 3000px
        border-radius: 0 20px 20px 0
    &__title
        font-size: 32px
        color: $main-color
        text-align: center
    h2
        font-size: 34px
        font-weight: 500
        text-align: center
        margin-top: 20px
    &__possibilities-wrapper
        border-radius: 20px
        margin-top: 30px
        display: flex
        justify-content: space-between
        gap: 40px
        padding-left: 40px
        box-shadow: 0 0 28px rgba(0,0,0,0.25), 0 0 10px rgba(0,0,0,0.22)
        background: #fff
    &__possibilities
        ul
            height: 100%
            list-style: none
            display: flex
            flex-direction: column
            justify-content: space-around
            margin: 0
            li
                text-align: center
                font-size: 18px
    &__blog
        margin-top: 30px
        &__wrapper
            padding-top: 25px
            display: flex
            align-items: center
            justify-content: center
            flex-wrap: wrap
            gap: calc( 80px / 3 )
        &__btn
            display: block
            margin: 25px auto
            text-align: center
            width: 100px
            cursor: pointer
            border-radius: 20px
            background-color: $main-color
            color: #FFFFFF
            font-size: 12px
            font-weight: bold
            padding: 12px 0
            letter-spacing: 1px
            text-transform: uppercase
            transition: transform 80ms ease-in
            &:hover
                color: #FFFFFF
            &:active
                transform: scale(0.95)
            &:focus
                outline: none