@import '../../styles/variables'
    
.header
    position: fixed
    width: 100%
    height: 80px
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
    font-weight: 500
    background: #fff
    z-index: 5
    &__close
        display: none
    &__hamburger
        display: none
    &__wrapper
        width: 100%
        height: 100%
        display: flex
        justify-content: space-between
        align-items: center
    &__logo-link
        display: flex
        align-items: center
        gap: 10px
        font-size: 24px
    &__logo
        width: 32px
    &__logo-mobile
        display: none
    &__nav
        position: absolute
        left: 50%
        transform: translateX(-50%)
        font-size: 24px
        display: flex
        gap: 1rem
    &__login
        cursor: pointer
        font-size: 32px
        transition: all 0.3s
        span
            font-size: 24px
            margin-left: 10px
        .arrow-down
            font-size: 16px
            margin-left: 5px
        &:hover
            color: $main-color
        &:hover .header__dropdown
            display: flex  
    &__logout
        font-size: 32px
        span, a
            font-size: 24px
            margin-left: 10px
        .logout
            cursor: pointer
            font-size: 24px
            margin-left: 10px
            transition: all 0.3s
            &:hover
                color: $main-color
    &__dropdown
        cursor: default
        position: absolute
        transform: translateX(-15%)
        display: none
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 10px
        width: 180px
        height: 90px
        background: #fff
        border-radius: 22px
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
        font-size: 18px
        text-transform: uppercase

