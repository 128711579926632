@import '../../styles/variables'

.login
    &__title
        text-align: center
        font-size: 32px
        font-weight: 700
        color: $main-color
    &__wrapper
        margin: 30px auto 0
        background-color: #fff
        border-radius: 10px
        box-shadow: 0 14px 28px rgba(0,0,0,0.25),0 10px 10px rgba(0,0,0,0.22)
        position: relative
        overflow: hidden
        width: 768px
        max-width: 100%
        min-height: 480px
    p 
        font-size: 14px
        font-weight: 100
        line-height: 20px
        letter-spacing: 0.5px
        margin: 20px 0 30px
    span 
        font-size: 12px
    a 
        color: #333
        font-size: 14px
        text-decoration: none
        margin: 15px 0
    button 
        cursor: pointer
        border-radius: 20px
        border: 1px solid $main-color
        background-color: $main-color
        color: #FFFFFF
        font-size: 12px
        font-weight: bold
        padding: 12px 45px
        letter-spacing: 1px
        text-transform: uppercase
        transition: transform 3s ease-in
        &:active
            transform: scale(0.95)
        &:focus
            outline: none
    button.ghost 
        background-color: transparent
        border-color: #FFFFFF
    form 
        background-color: #FFFFFF
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        padding: 0 50px
        height: 100%
        text-align: center
        h1
            font-size: 32px
    input 
        background-color: #eee
        outline: none
        border: none
        padding: 12px 15px
        margin: 8px 0
        width: 100%
        z-index: 2
    .form-container 
        position: absolute
        top: 0
        height: 100%
        transition: all 0.6s ease-in-out
    .sign-in-container 
        left: 0
        width: 50%
        z-index: 2
    .right-panel-active .sign-in-container 
        transform: translateX(100%)
    .sign-up-container 
        left: 0
        width: 50%
        opacity: 0
        z-index: 1
    .right-panel-active .sign-up-container 
        transform: translateX(100%)
        opacity: 1
        z-index: 3
        animation: show 0.6s
    @keyframes show 
        0%, 49.99% 
            opacity: 0
            z-index: 1	
        50%, 100% 
            opacity: 1
            z-index: 3	
    .overlay-container 
        position: absolute
        top: 0
        left: 50%
        width: 50%
        height: 100%
        overflow: hidden
        transition: transform 0.6s ease-in-out
        z-index: 4
        h1
            font-size: 32px
    .right-panel-active .overlay-container
        transform: translateX(-100%)
    .overlay 
        background: #009AF1
        background: -webkit-linear-gradient(to right, $main-color, #006ead) 
        // #007d2e
        background: linear-gradient(to right, $main-color, #006ead)
        background-repeat: no-repeat
        background-size: cover
        background-position: 0 0
        color: #FFFFFF
        position: relative
        left: -100%
        height: 100%
        width: 200%
        transform: translateX(0)
        transition: transform 0.6s ease-in-out
    .right-panel-active .overlay 
        transform: translateX(50%)
    .overlay-panel 
        position: absolute
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        padding: 0 40px
        text-align: center
        top: 0
        height: 100%
        width: 50%
        transform: translateX(0)
        transition: transform 0.6s ease-in-out
    .overlay-left 
        transform: translateX(-20%)
    .right-panel-active .overlay-left 
        transform: translateX(0)
    .overlay-right 
        right: 0
        transform: translateX(0)
    .right-panel-active .overlay-right 
        transform: translateX(20%)
    .social-container 
        margin: 20px 0
    .social-container a 
        border: 1px solid #DDDDDD
        border-radius: 50%
        display: inline-flex
        justify-content: center
        align-items: center
        margin: 0 5px
        height: 40px
        width: 40px