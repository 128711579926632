.search
    display: block
    max-width: 620px
    margin: 0 auto
    height: 50px
    border-radius: 20px
    box-shadow: 0 0 14px rgba(0,0,0,0.25), 0 0 5px rgba(0,0,0,0.22)
    display: flex
    align-items: center
    justify-content: space-around
    background: #fff
    &__by-text
        width: 500px
        position: relative
        i
            position: absolute
            font-size: 22px
            top: 50%
            transform: translateY(-50%)
            right: 10px
            color: #000
    &__by-type
        width: 120px
    &__by-country, &__by-state
        width: 300px
    &__by-date
        width: 150px
        text-align: center
        cursor: pointer
        div
            width: 100%
            height: 40px
            border-radius: 15px
            background-color: #eee
            display: flex
            align-items: center
            justify-content: center
            font-size: 14px
            padding: 12px 15px
    input, select
        width: 100%
        height: 40px
        border-radius: 15px
        background-color: #eee
        outline: none
        border: none
        padding: 10px 15px
        appearance: none
        font-size: 14px
    