.admin-panel
    &__add-form
        display: flex
        flex-direction: column
        div
            display: flex
            flex-direction: column
    input, select, textarea
        width: 600px
        height: 42px
        background-color: #eee
        outline: none
        border: none
        padding: 12px 15px
        margin: 8px 0
    input[type="file"]
        background-color: #fff
        padding: 0
    textarea
        height: 500px
        resize: none
    input[type="submit"]
        width: 600px
        cursor: pointer
        border-radius: 20px
        border: 1px solid #FF4B2B
        background-color: #FF4B2B
        color: #FFFFFF
        font-size: 12px
        font-weight: bold
        padding: 12px 0
        letter-spacing: 1px
        text-transform: uppercase
        transition: transform 80ms ease-in
        &:active
                transform: scale(0.95)
        &:focus
            outline: none
    .login
        &__wrapper
            width: 384px
        .sign-in-container
            width: 100%
        input 
            width: 100%