@import '../../styles/variables'

.blog-by-id
    .container
        max-width: 1000px
    &__wrapper
        padding-bottom: 30px
        position: relative
        
        display: flex
        flex-direction: column
    &__title
        font-size: 32px
        color: $main-color
        text-align: center
    img
        margin-top: 20px
        width: 100%
        max-height: 600px
        border-radius: 20px
        object-fit: cover
        object-position: center
    &__subtitle
        margin-top: 30px
        color: $main-color
        font-size: 22px
        font-weight: 500
    &__text
        max-width: 1000px
        font-size: 18px
        font-weight: 300
        letter-spacing: 0.04rem
        margin-top: 5px
    span
        position: absolute
        bottom: 0
        right: 0
        color: rgba(0, 0, 0, 0.5)
    i
        position: absolute
        top: 10px
        cursor: pointer
        font-size: 24px
        transition: all 0.3s
        &:hover
            color: $main-color
    &__modal
        width: 250px
        height: 150px
        border-radius: 20px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 20px
        background: #fff
        font-size: 24px
        font-weight: 700
        position: absolute
        top: 300px
        left: 50%
        transform: translate(-45%, -50%)
        box-shadow: 0 0 28px rgba(0, 0, 0, 0.25), 0 0 10px rgba(0, 0, 0, 0.22)
        transition: all 0.5s
        &_none
            display: none
    &__btns
        display: flex
        gap: 20px
    button
        cursor: pointer
        border-radius: 20px
        width: 100px
        border: 1px solid $main-color
        background-color: $main-color
        color: #FFFFFF
        font-size: 12px
        font-weight: bold
        padding: 10px 0
        letter-spacing: 1px
        text-transform: uppercase
        transition: transform 80ms ease-in
        &:active
            transform: scale(0.95)
        &:focus
            outline: none
            