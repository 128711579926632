@import '../../styles/variables'
    
.guide-by-id
    &__title
        font-size: 32px
        color: $main-color
        text-align: center
    &__form
        margin: 40px auto
        width: 800px
        display: flex
        flex-direction: column
        align-items: center
        gap: 40px
    &__location, &__info
        width: 350px
        display: flex
        flex-direction: column
        gap: 10px
    &__about
        width: 100%
        display: flex
        justify-content: center
        gap: 60px
    &__img
        width: 350px
        height: 250px
        border-radius: 100%
        display: flex
        justify-content: center
        align-items: center
        img
            width: 250px
            height: 250px
            border-radius: 100%
            object-fit: cover
            object-position: center            
        label
            position: absolute
            cursor: pointer
            background-color: #0000004d
            color: #fff
            width: 250px
            height: 250px
            border-radius: 100%
            display: flex
            justify-content: center
            align-items: center
            opacity: 0
            transition: all 0.2s
            &:hover
                opacity: 1
        input
            display: none
        
    input, &__dropdown
        height: 40px
        background-color: #eee
        outline: none
        border: none
        padding: 12px 15px
        margin: 8px 0
        width: 100%
    input[type="submit"]
        width: 350px
        cursor: pointer
        border-radius: 20px
        border: 1px solid $main-color
        background-color: $main-color
        color: #FFFFFF
        font-size: 12px
        font-weight: bold
        padding: 12px 0
        letter-spacing: 1px
        text-transform: uppercase
        transition: transform 80ms ease-in
        &:active
                transform: scale(0.95)
        &:focus
            outline: none
    select
        height: 40px
        background-color: #eee
        outline: none
        border: none
        padding: 12px 15px
        margin: 8px 0
        width: 100%
    option
        font-size: 14px
    textarea
        margin-top: 8px
        width: 350px
        height: 223px
        padding: 15px
        background-color: #eee
        display: block
        resize: none
        outline: none
        border: none
        font-size: 16px
