@import './variables'
    
*
    margin: 0
    padding: 0
    box-sizing: border-box
    font-family: 'Roboto', sans-serif
    &::-webkit-scrollbar 
        display: none
body
    background: #eee
a
    color: #000
    text-decoration: none
    transition: all 0.3s
    &:hover
        color: $main-color
main
    padding: 50px 20px
    min-height: 100vh
.container
    max-width: 1280px
    padding-right: 0
    padding-left: 0
    margin: 0 auto
ul
    padding: 0
.app
    display: flex
    flex-direction: column
    justify-content: space-between
    &__sections
        padding-top: 80px